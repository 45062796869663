import axios from "axios";
import {
  showErrorNotification,
  startLoading,
  stopLoading,
} from "@/utils/errors";

export default {
  namespaced: true,
  state: {
    booking_data: {
      car: null,
      customer: null,
      date: null,
      service: null,
      workshop: null,
      serviceList: null
    },
  },
  getters: {
    getBookingData: (state) => state.booking_data,
    getCar: (state) => state.booking_data.car,
    getCustomer: (state) => state.booking_data.customer,
    getService: (state) => state.booking_data.service,
    getDate: (state) => state.booking_data.date,
    getWorkshop: (state) => state.booking_data.workshop,
    getServiceList: (state) => state.serviceList,
  },
  mutations: {
    SET_BOOKING_DATA(state, data) {
      state.booking_data = data;
    },
    SET_CAR_DATA(state, data) {
      state.booking_data.car = data;
    },
    SET_CUSTOMER_DATA(state, data) {
      state.booking_data.customer = data;
    },
    SET_SERVICE_DATA(state, data) {
      state.booking_data.service = data;
    },
    SET_DATE_DATA(state, data) {
      state.booking_data.date = data;
    },
    SET_WORKSHOP_DATA(state, data) {
      state.booking_data.workshop = data;
    },
    SET_SERVICE_LIST(state, data) {
      state.serviceList = data;
    },
  },
  actions: {
    //Booking Data functions
    saveCar({ commit }, carData) {
      commit("SET_CAR_DATA", carData);
    },
    saveCustomer({ commit }, customerData) {
      commit("SET_CUSTOMER_DATA", customerData);
    },
    saveService({ commit }, customerData) {
      commit("SET_CUSTOMER_DATA", customerData);
    },
    saveDate({ commit }, dateData) {
      commit("SET_DATE_DATA", dateData);
    },
    saveWorkshop({ commit }, workshopData) {
      commit("SET_WORKSHOP_DATA", workshopData);
    },
    saveBooking({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        startLoading(dispatch);
        axios
          .post(
            process.env.VUE_APP_SERVER_URL + `/workshop/booking`,
            state.booking_data
          )
          .then((res) => {
            commit("SET_BOOKING", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          })
          .finally(stopLoading(dispatch));
      });
    },
    loadServiceList({ commit, dispatch}) {
      return new Promise((resolve, reject) => {
        startLoading(dispatch);
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/workshop/category`)
          .then((res) => {
            commit("SET_SERVICE_LIST", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          })
          .finally(stopLoading(dispatch));
      });
    }
  },
};
