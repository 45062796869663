/* eslint-disable */

export class DropImageFetchError extends Error {
  constructor() {
    super("can't process cross-origin image");

    this.name = "DropImageFetchError";
  }
}

export class DropImageDecodeError extends Error {
  constructor() {
    super("drag-and-dropped file is not of type image and can't be decoded");

    this.name = "DropImageDecodeError";
  }
}

export class StreamApiNotSupportedError extends Error {
  constructor() {
    super("this browser has no Stream API support");

    this.name = "StreamApiNotSupportedError";
  }
}

export class InsecureContextError extends Error {
  constructor() {
    super(
      "camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP."
    );

    this.name = "InsecureContextError";
  }
}

export function showErrorNotification (dispatch, err) {
  console.log(err);
  dispatch(
    "notifications/addNotification",
    {
      message: err.response.data.message ? err.response.data.message : err.response.data.error,
      type: "error"
    },
    { root: true }
  );
}

export function showSuccessNotification (dispatch, message) {
  dispatch(
    "notifications/addNotification",
    {
      message: message,
      type: "success"
    },
    { root: true }
  );
}

export function startLoading(dispatch) {
  dispatch(
    "loading/setLoading",
    true,
    { root: true }
  );
}

export function stopLoading(dispatch) {
  dispatch(
    "loading/setLoading",
    false,
    { root: true }
  );
}