export const vinRoutes = [
  {
    path: "/vin-start",
    name: "VinStart",
    component: () => import("./views/VinStart.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/vin-scan",
    name: "VinScan",
    component: () => import("./views/VinScan.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/vin-scan-check",
    name: "VinScanCheck",
    component: () => import("./views/VinScanCheck.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/vin-manual",
    name: "VinManual",
    component: () => import("./views/VinManual.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/vin-registration",
    name: "VinRegistration",
    component: () => import("./views/VinRegistration.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/vin-confirmation",
    name: "VinConfirmation",
    component: () => import("./views/VinConfirmation.vue"),
    meta: { requiresAuth: true },
  },
];
