import { createApp } from "vue";
import App from "./Mvp.vue";
import "./registerServiceWorker";
import router from "./router/mvp";
import store from "./store";
import "@/assets/styles/main.scss";
import axios from "axios";
import VueAxios from "vue-axios";
import InlineSvg from "vue-inline-svg";
import { createI18n } from "vue-i18n";
import messages from "./i18n/translations";
import Vue3TouchEvents from "vue3-touch-events";
import VueGtag from "vue-gtag";
import formatter from "./modules/core/plugins/formatter";
import utility from "./modules/core/plugins/utility";
import drag from "v-drag";

const DEFAULT_LNG = "de";
let language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "de";

const i18n = createI18n({
  locale: language, // set locale
  fallbackLocale: DEFAULT_LNG,
  globalInjection: true,
  messages,
});

//axios.defaults.withCredentials = true;

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Vue3TouchEvents)
  .use(VueAxios, axios)
  .use(formatter)
  .use(utility)
  .use(drag)
  .use(VueGtag, {
    config: { id: "G-WNHKT99R4L" },
  })
  .component("inline-svg", InlineSvg)
  .mount("#app");
