export const userRoutes = [
  {
    path: "/login-choice",
    name: "LoginChoice",
    component: () => import("./views/UserLoginChoice.vue"),
    meta: { navbar: false },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("./views/UserLogin.vue"),
    meta: { navbar: false },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/UserRegister.vue"),
    meta: { navbar: false },
  },
  {
    path: "/password-forgot",
    name: "PasswordForgot",
    component: () => import("./views/UserPasswordForgot.vue"),
  },
  {
    path: "/password-new",
    name: "PasswordNew",
    component: () => import("./views/UserPasswordNew.vue"),
  },
  {
    path: "/validate-email",
    name: "ValidateEmail",
    component: () => import("./views/UserValidateEmail.vue"),
  },
];
