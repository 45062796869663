import axios from "axios";
import { showErrorNotification } from "@/utils/errors";

export default {
  namespaced: true,
  state: {
    productList: null,
    product: null,
    amazonData: null,
  },
  getters: {
    getProductList: (state) => state.productList,
    getProduct: (state) => state.product,
    getAmazonData: (state) => state.amazonData,
  },
  mutations: {
    SET_PRODUCT_LIST(state, payload) {
      state.productList = payload;
    },
    SET_PRODUCT(state, payload) {
      state.product = payload;
    },
    SET_AMAZON_DATA(state, payload) {
      state.amazonData = payload;
    },
  },
  actions: {
    loadProductList(
      { commit, rootGetters, dispatch },
      { partId, productType, group }
    ) {
      return new Promise((resolve, reject) => {
        const user = rootGetters["user/getUser"];
        let params = {};

        if (productType == "oil") {
          params = {
            oil_id: partId,
            vehicle_id: user.active_vehicle_uuid,
          };
        } else if (group) {
          params = {
            group: group,
            vehicle_id: user.active_vehicle_uuid,
          };
        } else {
          params = {
            part_id: partId,
            vehicle_id: user.active_vehicle_uuid,
          };
        }

        axios
          .get(process.env.VUE_APP_SERVER_URL + "/product", {
            params: params,
          })
          .then((res) => {
            commit("SET_PRODUCT_LIST", res.data);
            resolve();
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject(err);
          });
      });
    },
    loadProduct({ commit, rootGetters, dispatch }, productId) {
      return new Promise((resolve, reject) => {
        const user = rootGetters["user/getUser"];
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/product/${productId}`, {
            crossDomain: true,
            params: {
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_PRODUCT", res.data);
            resolve();
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadAmazonData({ commit, dispatch }, asin) {
      console.log(commit);
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/amazon/${asin}`)
          .then((res) => {
            console.log(res.data);
            commit("SET_AMAZON_DATA", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            console.log(err);
            showErrorNotification(dispatch, err);
            reject(err);
          });
      });
    },
  },
};
