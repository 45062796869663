export const eventRoutes = [
  {
    path: "/event",
    name: "Events",
    component: () => import("./views/EventOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/event-details/:id",
    name: "EventDetails",
    component: () => import("./views/EventDetail.vue"),
    meta: { requiresAuth: true },
  },
];
