import axios from "axios";
import { Geolocation } from "@capacitor/geolocation";
import { latLng } from "leaflet";

export default {
  namespaced: true,
  state: {
    gasStations: [],
    gasStation: null,
    position: null,
    settings: JSON.parse(localStorage.getItem("gas_station.settings")) || {
      fuelType: "e5",
      sortBy: "distance",
      radius: 10,
      open_only: false,
      fav: false,
      brands: [],
    },
    locationPermission:
      JSON.parse(localStorage.getItem("gas_station.locationPermission")) ||
      null,
  },
  getters: {
    getGasStations: (state) => state.gasStations,
    getGasStation: (state) => state.gasStation,
    getPosition: (state) => state.position,
    getSettings: (state) => state.settings,
    getSetting: (state) => (key) => {
      return state.settings[key] ? state.settings[key] : null;
    },
  },
  mutations: {
    SET_GAS_STATIONS(state, payload) {
      state.gasStations = payload;
    },
    SET_GAS_STATION(state, payload) {
      state.gasStation = payload;
    },
    SET_POSITION(state, position) {
      state.position = position;
    },
    SET_SETTINGS(state, settings) {
      state.settings = settings;
      localStorage.setItem(
        "gas_station.settings",
        JSON.stringify(state.settings)
      );
    },
    SET_LOCATION_PERMISSION(state, permission) {
      state.locationPermission = permission;
      localStorage.setItem(
        "gas_station.locationPermission",
        JSON.stringify(permission)
      );
    },
    RESET_SETTINGS(state) {
      state.settings = {
        fuelType: "e5",
        sortBy: "distance",
        radius: 10,
        open_only: false,
        fav: false,
        brands: [],
      };
      localStorage.setItem(
        "gas_station.settings",
        JSON.stringify(state.settings)
      );
    },
  },
  actions: {
    setSetting({ commit, getters }, parameter) {
      if (!parameter.key) {
        return;
      }

      let settings = getters.getSettings;
      settings[parameter.key] = parameter.value;

      commit("SET_SETTINGS", settings);
    },
    loadGasStations({ commit, dispatch, state }, parameter) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/petrol/station", {
            params: {
              lat: parameter.location.lat,
              lng: parameter.location.lng,
              radius: state.settings.radius,
              open_only: state.settings.open_only,
              brands: Array.isArray(state.settings.brands)
                ? state.settings.brands.join(",")
                : "",
              fuel_type: state.settings.fuelType,
              fav: state.settings.fav,
              sort_by: state.settings.sortBy,
            },
          })
          .then((res) => {
            commit("SET_GAS_STATIONS", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          });
      });
    },
    loadGasStation({ commit, dispatch }, station_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/petrol/station/${station_id}`)
          .then((res) => {
            commit("SET_GAS_STATION", res.data);
            resolve();
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          });
      });
    },
    setPosition({ commit }, position) {
      commit("SET_POSITION", position);
    },
    setSettings({ commit }, settings) {
      commit("SET_SETTINGS", settings);
    },
    checkPermissions() {},
    hasPositionPermission({ state, commit }) {
      console.log("CHECK POS PERMISSION");
      return new Promise((resolve, reject) => {
        if (state.locationPermission) {
          resolve(state.locationPermission == "granted");
        }

        Geolocation.checkPermissions()
          .then((res) => {
            console.log("CHECKED PERMISSION", res);
            if (res.location === "denied") {
              commit("SET_LOCATION_PERMISSION", "denied");
              resolve(false);
            } else if (res.location === "granted") {
              commit("SET_LOCATION_PERMISSION", "granted");
              resolve(true);
            } else if (res.location === "prompt") {
              Geolocation.requestPermissions()
                .then((res) => {
                  console.log("REQUESTED PERMISSION", res);
                })
                .catch(() => {
                  Geolocation.getCurrentPosition()
                    .then((location) => {
                      console.log("CURRENT POSITION", location);
                      commit(
                        "SET_POSITION",
                        latLng(
                          location.coords.latitude,
                          location.coords.longitude
                        )
                      );
                    })
                    .catch((err) => {
                      if (err.code === 1) {
                        commit("SET_LOCATION_PERMISSION", "denied");
                        resolve(false);
                      }

                      resolve(false);
                    });
                });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updatePosition({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        console.log("UPDATE POSITION", state.locationPermission);

        if (state.locationPermission !== "granted") {
          if (state.locationPermission === "denied") {
            dispatch(
              "notifications/addNotification",
              {
                message: "gasStation.notifications.position_denied",
                type: "error",
              },
              { root: true }
            );
          }
          return reject(false);
        }

        try {
          Geolocation.getCurrentPosition()
            .then((location) => {
              commit(
                "SET_POSITION",
                latLng(location.coords.latitude, location.coords.longitude)
              );
              resolve(location);
            })
            .catch(() => {
              reject(false);
            });
        } catch (err) {
          reject(false);
        }
      });
    },
    resetSettings({ commit }) {
      commit("RESET_SETTINGS");
    },
  },
};
