import axios from "axios";

export default {
  namespaced: true,
  state: {
    loaded: false,
    messages: [],
    singleMessage: null,
  },
  getters: {
    getMessages: (state) => state.messages,
    getMessage: (state) => state.singleMessage,
    getLanguage: (state) => state.language,
    getReferral: (state) => state.referral,
    isLoaded: (state) => state.loaded,
  },
  mutations: {
    SET_LOADED(state, payload) {
      state.loaded = payload;
    },
    SET_MESSAGES(state, payload) {
      state.messages = payload;
    },
    SET_SINGLE_MESSAGE(state, payload) {
      state.singleMessage = payload;
    },
    SET_LANGUAGE(state, payload) {
      state.language = payload;
    },
    SET_REFERRAL(state, referral) {
      state.referral = referral;
    },
  },
  actions: {
    loadMessages({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/user/inbox")
          .then((res) => {
            commit("SET_MESSAGES", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          })
          .finally(commit("SET_LOADED", true));
      });
    },
    loadMessage({ commit, dispatch }, message_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/user/inbox/${message_id}`)
          .then((res) => {
            commit("SET_SINGLE_MESSAGE", res.data);
            resolve();
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          });
      });
    },
    updateMessage({ commit, dispatch }, message) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            process.env.VUE_APP_SERVER_URL + `/user/inbox/${message.uuid}`,
            message
          )
          .then((res) => {
            commit("SET_SINGLE_MESSAGE", res.data);
            resolve(message);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
            console.log(err);
          });
      });
    },
    deleteMessage({ dispatch }, message) {
      axios
        .delete(process.env.VUE_APP_SERVER_URL + `/user/inbox/${message.uuid}`)
        .then(() => {
          dispatch(
            "notifications/addNotification",
            {
              message: "message.notifications.deleted",
              type: "success",
            },
            { root: true }
          );
          dispatch("loadMessages");
        })
        .catch((err) => {
          dispatch(
            "notifications/addNotification",
            {
              message: err.response.data.error,
              type: "error",
            },
            { root: true }
          );
          console.log(err);
        });
    },
  },
};
