export const carRoutes = [
  {
    path: "/car-park",
    name: "CarPark",
    component: () => import("./views/CarOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/car-detail/:id",
    name: "CarDetails",
    component: () => import("./views/CarDetail.vue"),
    meta: { requiresAuth: true },
  },
];
