import axios from "axios";
import { showErrorNotification } from "@/utils/errors";

export default {
  namespaced: true,
  state: {
    item: null,
    list: null,
    dashboardList: null,
    searchList: null,
    subList: null,
    subDetails: null,
    details: null,
    genericList: null,
  },
  getters: {
    getPackage: (state) => state.item,
    getList: (state) => state.list,
    getSubList: (state) => state.subList,
    getSubDetails: (state) => state.subDetails,
    getDashboardList: (state) => state.dashboardList,
    getSearchList: (state) => state.searchList,
    getDetails: (state) => state.details,
  },
  mutations: {
    SET_PACKAGE(state, item) {
      state.item = item;
    },
    SET_LIST(state, list) {
      state.list = list;
    },
    SET_SUB_LIST(state, list) {
      state.subList = list;
    },
    SET_SUB_DETAILS(state, details) {
      state.subDetails = details;
    },
    SET_DASHBOARD_LIST(state, list) {
      state.dashboardList = list;
    },
    SET_SEARCH_LIST(state, list) {
      state.searchList = list;
    },
    SET_DETAILS(state, payload) {
      state.details = payload;
    },
  },
  actions: {
    loadPackages({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle/package", {
            params: {
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_LIST", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadDashboardPackages({ commit, dispatch }, carId) {
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/vehicle/package/dashboard", {
          params: {
            vehicle_id: carId,
          },
        })
        .then((res) => {
          commit("SET_DASHBOARD_LIST", res.data);
        })
        .catch((err) => {
          showErrorNotification(dispatch, err);
        });
    },
    setPackage({ commit }, item) {
      commit("SET_PACKAGE", item);
    },
    setSubPackages({ commit }, subPackage) {
      commit("SET_SUB_LIST", subPackage);
    },
    loadSubDetails({ commit, rootGetters, dispatch }, identifier) {
      return new Promise((resolve, reject) => {
        const user = rootGetters["user/getUser"];
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/vehicle/part/`, {
            params: {
              submodule_id: identifier,
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_SUB_DETAILS", res.data);
            resolve();
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject(err);
          });
      });
    },
    loadGenericDetails({ commit, rootGetters, dispatch }, identifier) {
      return new Promise((resolve, reject) => {
        const user = rootGetters["user/getUser"];
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/vehicle/part/`, {
            params: {
              generic_article_id: identifier,
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_SUB_DETAILS", res.data);
            resolve();
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject(err);
          });
      });
    },
    loadGenericArticles({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/vehicle/generic_article", {
          params: {
            vehicle_id: user.active_vehicle_uuid,
          },
        })
        .then((res) => {
          commit("SET_SEARCH_LIST", res.data);
        })
        .catch((err) => {
          showErrorNotification(dispatch, err);
        });
    },
    loadProducts({ commit, rootGetters, dispatch }, identifier) {
      const user = rootGetters["user/getUser"];
      axios
        .get(process.env.VUE_APP_SERVER_URL + `/vehicle/subpackage`, {
          params: {
            submodule_id: identifier,
            vehicle_id: user.active_vehicle_uuid,
          },
        })
        .then((res) => {
          commit("SET_SUB_DETAILS", res.data);
        })
        .catch((err) => {
          showErrorNotification(dispatch, err);
        });
    },
  },
};
