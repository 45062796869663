import axios from "axios";
import {
  showErrorNotification,
  startLoading,
  stopLoading,
} from "@/utils/errors";

export default {
  namespaced: true,
  state: {
    content: null,
  },
  getters: {
    getContent: (state) => state.content,
  },
  mutations: {
    SET_CMS(state, payload) {
      state.content = payload.data;
    },
  },
  actions: {
    loadPage({ commit, dispatch }, key) {
      return new Promise((resolve, reject) => {
        startLoading(dispatch);
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/content/page/${key}`)
          .then((res) => {
            commit("SET_CMS", {
              key: key,
              data: res.data,
            });
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err.data);
            console.log(err);
            reject();
          })
          .finally(stopLoading(dispatch));
      });
    },
  },
};
