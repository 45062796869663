import axios from "axios";
import { showErrorNotification } from "@/utils/errors";
import { Promise } from "core-js";

export default {
  namespaced: true,
  state: {
    fuel_amount: null,
    price_data: null,
    fuel_stops: null,
    last_entry: null,
  },
  getters: {
    getFuelAmount: (state) => state.fuel_amount,
    getTotalFuelAmount: (state) =>
      state.fuel_amount.reduce(function (sum, current) {
        return sum + parseFloat(current.value);
      }, 0),
    getFuelPriceData: (state) => state.price_data,
    getCostData: (state) => state.cost_data,
    getFuelStops: (state) => state.fuel_stops,
    getLastFuelPrice: (state) => state.last_entry?.custom_fields?.price,
    getLastFuelAmount: (state) => state.last_entry?.custom_fields?.amount,
  },
  mutations: {
    SET_FUEL_AMOUNT(state, data) {
      state.fuel_amount = data;
    },
    SET_PRICE_DATA(state, data) {
      state.price_data = data;
    },
    SET_COST_DATA(state, data) {
      state.cost_data = data;
    },
    SET_FUEL_STOPS(state, data) {
      state.fuel_stops = data;
    },
    SET_LAST_ENTRY(state, data) {
      state.last_entry = data;
    },
  },
  actions: {
    loadFuelAmount({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle/statistic/refuel", {
            params: {
              type: "amount",
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_FUEL_AMOUNT", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadFuelPriceData({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_SERVER_URL + "/vehicle/statistic/refuel_price",
            {
              params: {
                timeframe: "weekly",
                vehicle_id: user.active_vehicle_uuid,
              },
            }
          )
          .then((res) => {
            commit("SET_PRICE_DATA", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadCostData({ commit, rootGetters, dispatch }, timeframe) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle/statistic/cost", {
            params: {
              timeframe: timeframe,
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_COST_DATA", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadFuelStops({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle/statistic/refuel", {
            params: {
              type: "stops",
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_FUEL_STOPS", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadLastEntry({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle/statistic/refuel", {
            params: {
              type: "last_entry",
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_LAST_ENTRY", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
  },
};
