import { createStore } from "vuex";

import user from "@/modules/user/store/user";
import onboarding from "@/modules/core/store/onboarding";
import vin from "@/modules/vin/store/vin";
import packages from "@/modules/package/store/package";
import events from "@/modules/event/store/event";
import cms from "@/modules/cms/store/cms";
import products from "@/modules/product/store/product";
import notifications from "@/modules/core/store/notifications";
import loading from "@/modules/core/store/loading";
import history from "@/modules/core/store/history";
import fluids from "@/modules/fluid/store/fluid";
import wearparts from "@/modules/core/store/wearparts";
import voucher from "@/modules/voucher/store/voucher";
import activity from "@/modules/activity/store/activity";
import oil from "@/modules/oil/store/oil";
import message from "@/modules/message/store/message";
import car from "@/modules/car/store/car";
import gas from "@/modules/gas/store/gas";
import statistic from "@/modules/statistic/store/statistic";
import workshop from "@/modules/workshop/store/workshop";
import booking from "@/modules/workshop/store/booking";

export default createStore({
  modules: {
    user,
    message,
    onboarding,
    vin,
    packages,
    wearparts,
    events,
    cms,
    products,
    notifications,
    loading,
    history,
    fluids,
    voucher,
    activity,
    oil,
    car,
    gas,
    statistic,
    workshop,
    booking,
  },
});
