<template>
    <div class="lb-pull-refresh" ref="root">
      <div
        class="lb-pull-refresh__track"
        :style="trackStyle"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        @touchcancel="onTouchend"
      >
        <div :style="getHeadStyle" class="lb-pull-refresh__head">
            <inline-svg
              :style="getIconStyle"
              class="refresh__icon"
              :src="require(`@/assets/icons/refresh-cw.svg`)"
              aria-label="type"
            ></inline-svg>
          <div v-if="TEXT_STATUS.includes(status)" class="pull-text">{{getStatusText()}}</div>
          <div v-if="status==='loading'" class="pull-text-loading">{{loadingText}}</div>
        </div>
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, watch, reactive, nextTick, computed, defineComponent, toRefs } from 'vue';
  
  import useScrollParent from './composables/useScrollParent';
  import useTouch from './composables/useTouch';
  
  const DEFAULT_HEAD_HEIGHT = 70;
  
  function getScrollTop(el) {
    const top = 'scrollTop' in el ? el.scrollTop : el.pageYOffset
    return Math.max(top, 0);
  }
  
  function preventDefault(event, isStopPropagation) {
    if (typeof event.cancelable !== 'boolean' || event.cancelable) {
      event.preventDefault()
    }
    if (isStopPropagation) {
      event.stopPropagation(event)
    }
  }
  
  export default defineComponent({
    name: 'pull-refresh',
    props: {
      disabled: Boolean,
      successText: {
        type: String,
        default: 'Erfolgreich'
      },
      pullingText: {
        type: String,
        default: 'Refresh...'
      },
      loosingText: {
        type: String,
        default: 'Release for Refresh...'
      },
      loadingText: {
        type: String,
        default: 'Loading...'
      },
      pullDistance: [Number, String],
      modelValue: {
        type: Boolean,
        default: false,
      },
      successDuration: {
        type: [Number, String],
        default: 500,
      },
      animationDuration: {
        type: [Number, String],
        default: 300,
      },
      headHeight: {
        type: [Number, String],
        default: DEFAULT_HEAD_HEIGHT,
      },
    },
  
    emits: ['refresh', 'update:modelValue'],
  
    setup(props, { emit, slots }) {
      let reachTop = false;
      const TEXT_STATUS = ['pulling', 'loosing', 'success']
  
      const root = ref(null)
      let scrollParent = useScrollParent(root)
  
      const state = reactive({
        status: 'normal',
        distance: 0,
        duration: 0
      });
      
      const trackStyle = ref({});
  
      const touch = useTouch();
  
      const getHeadStyle = computed(() => {
        if (props.headHeight !== DEFAULT_HEAD_HEIGHT) {
          return {
            height: `${props.headHeight}px`,
            opacity: state.distance / +(props.pullDistance || props.headHeight)
          }
        }
        return {
          opacity: state.distance / +(props.pullDistance || props.headHeight)
        }
      })

      const getIconStyle = computed(() => {
        return {
          transform: `rotate(${state.distance / +(props.pullDistance || props.headHeight)}turn)`
        }
      })
  
      const isTouchable = () =>
        state.status !== 'loading' &&
        state.status !== 'success' &&
        !props.disabled;
  
      const ease = (distance) => {
        const pullDistance = +(props.pullDistance || props.headHeight);
  
        if (distance > pullDistance) {
          if (distance < pullDistance * 2) {
            distance = pullDistance + (distance - pullDistance) / 2;
          } else {
            distance = pullDistance * 1.5 + (distance - pullDistance * 2) / 4;
          }
        }
  
        return Math.round(distance);
      };
  
      const setTrackStyle = () => {
        return {
          transitionDuration: `${state.duration}ms`,
          transform: state.distance
            ? `translate3d(0,${state.distance}px, 0)`
            : ''
        }
      }
  
      const setStatus = (distance, isLoading) => {
        const pullDistance = +(props.pullDistance || props.headHeight);
        state.distance = distance;
  
        if (isLoading) {
          state.status = 'loading';
        } else if (distance === 0) {
          state.status = 'normal';
        } else if (distance < pullDistance) {
          state.status = 'pulling';
        } else {
          state.status = 'loosing';
        }
        trackStyle.value = setTrackStyle()
      };
  
      const getStatusText = () => {
        const { status } = state;
        if (status === 'normal') {
          return '';
        }
        return props[`${status}Text`] || '';
      };
  
      const showSuccessTip = () => {
        state.status = 'success';
  
        setTimeout(() => {
          setStatus(0);
        }, +props.successDuration);
      };
  
      const checkPosition = (event) => {
        reachTop = getScrollTop(scrollParent.value) === 0;
  
        if (reachTop) {
          state.duration = 0;
          touch.start(event);
        }
      };
  
      const onTouchStart = (event) => {
        if (isTouchable()) {
          checkPosition(event);
        }
      };
  
      const onTouchMove = (event) => {
        if (isTouchable()) {
          if (!reachTop) {
            checkPosition(event);
          }
  
          const { deltaY } = touch;
          touch.move(event);
  
          if (reachTop && deltaY.value >= 0 && touch.isVertical()) {
            preventDefault(event);
            setStatus(ease(deltaY.value));
          }
        }
      };
  
      const onTouchEnd = () => {
        if (reachTop && touch.deltaY.value && isTouchable()) {
          state.duration = +props.animationDuration;
  
          if (state.status === 'loosing') {
            setStatus(+props.headHeight, true);
            emit('update:modelValue', true);
  
            // ensure value change can be watched
            nextTick(() => emit('refresh'));
          } else {
            setStatus(0);
          }
        }
      };
  
      watch(
        () => props.modelValue,
        (value) => {
          state.duration = +props.animationDuration;
  
          if (value) {
            setStatus(+props.headHeight, true);
          } else if (slots.success || props.successText) {
            showSuccessTip();
          } else {
            setStatus(0, false);
          }
        }
      )
  
      return {
        ...toRefs(state),
        root,
        trackStyle,
        TEXT_STATUS,
        getHeadStyle,
        getIconStyle,
        getStatusText,
        onTouchStart,
        onTouchMove,
        onTouchEnd
      }
    }
  })
  </script>
  
  <style lang="scss">
  :root {
    --lb-pull-refresh-head-height: 70px;
    --lb-pull-refresh-head-font-size: 14px;
    --lb-pull-refresh-head-text-color: #FFFFFF;
    --lb-pull-refresh-loading-icon-size: 16px;
  }
  
  .lb-pull-refresh {
    overflow: hidden;
    user-select: none;
    height: 100%;
    @apply bg-vi-dark-blue;
  }

  .lb-pull-refresh__track {
    position: relative;
    height: 100%;
    transition-property: transform;
  }
  
  .lb-pull-refresh__head {
      position: absolute;
      left: 0;
      width: 100%;
      height: var(--lb-pull-refresh-head-height);
      overflow: hidden;
      color: var(--lb-pull-refresh-head-text-color);
      font-size: var(--lb-pull-refresh-head-font-size);
      line-height: var(--lb-pull-refresh-head-height);
      text-align: center;
      transform: translateY(-100%);
    }
  .pull-text {
    margin-top: -15px;
  }

  .refresh__icon {
    @apply mx-auto mt-4;
    height: 1rem;
  }
  </style>