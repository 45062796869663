import appDE from "./de/app.json";
import coreDE from "@/modules/core/i18n/de.json";
import pagesDE from "./de/pages.json";
import componentsDE from "./de/components.json";
import notificationsDE from "./de/notifications.json";
import tecDE from "./de/tec.json";
import voucherDE from "@/modules/voucher/i18n/de.json";
import activityDE from "@/modules/activity/i18n/de.json";
import cmsDE from "@/modules/cms/i18n/de.json";
import eventDE from "@/modules/event/i18n/de.json";
import packageDE from "@/modules/package/i18n/de.json";
import productDE from "@/modules/product/i18n/de.json";
import userDE from "@/modules/user/i18n/de.json";
import oilDE from "@/modules/oil/i18n/de.json";
import carDE from "@/modules/car/i18n/de.json";
import vinDE from "@/modules/vin/i18n/de.json";
import gasStationDE from "@/modules/gas/i18n/de.json";
import messageDE from "@/modules/message/i18n/de.json";
import statisticDE from "@/modules/statistic/i18n/de.json";
import workshopDE from "@/modules/workshop/i18n/de.json";

const translations = {
  en: require("./en"),
  de: Object.assign(
    {},
    appDE,
    coreDE,
    pagesDE,
    componentsDE,
    notificationsDE,
    tecDE,
    voucherDE,
    activityDE,
    cmsDE,
    eventDE,
    packageDE,
    productDE,
    userDE,
    oilDE,
    carDE,
    vinDE,
    gasStationDE,
    messageDE,
    statisticDE,
    workshopDE
  ),
};

export default translations;
