import axios from "axios";

export default {
  namespaced: true,
  state: {
    slides: [],
  },
  getters: {
    getSlides: (state) => state.slides,
  },
  mutations: {
    SET_SLIDES(state, slides) {
      state.slides = slides;
    },
  },
  actions: {
    loadSlides({ commit, dispatch }, type) {
      return new Promise((resolve, reject) => {
        type = type ? type : "start";
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/content/onboard/${type}`)
          .then((res) => {
            commit("SET_SLIDES", res.data);
            resolve();
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject();
            console.log(err);
          });
      });
    },
  },
};
