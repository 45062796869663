import axios from "axios";

export default {
  namespaced: true,
  state: {
    events: null,
    activeEvent: null,
    dashboardEvents: null,
  },
  getters: {
    getEvents: (state) => state.events,
    getActiveEvent: (state) => state.activeEvent,
    getDashboardEvents: (state) => state.dashboardEvents,
  },
  mutations: {
    SET_EVENTS(state, payload) {
      state.events = payload;
    },
    SET_ACTIVE_EVENT(state, event) {
      state.activeEvent = event;
    },
    SET_DASHBOARD_EVENTS(state, payload) {
      state.dashboardEvents = payload;
    },
  },
  actions: {
    loadEvents({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];

      if (!user.active_vehicle_uuid) {
        return commit("SET_EVENTS", null);
      }

      axios
        .get(process.env.VUE_APP_SERVER_URL + "/vehicle/event", {
          params: {
            vehicle_id: user.active_vehicle_uuid,
          },
        })
        .then((res) => {
          commit("SET_EVENTS", res.data);
        })
        .catch((err) => {
          dispatch(
            "notifications/addNotification",
            {
              message: err.response.data.error,
              type: "error",
            },
            { root: true }
          );
          console.log(err);
        });
    },
    loadActiveEvent({ commit, rootGetters, dispatch }, eventId) {
      return new Promise((resolve, reject) => {
        const user = rootGetters["user/getUser"];
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/vehicle/event/${eventId}`, {
            params: {
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_ACTIVE_EVENT", res.data);
            resolve();
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            console.log(err);
            reject();
          });
      });
    },
    loadDashboardEvents({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/vehicle/event/dashboard", {
          params: {
            vehicle_id: user.active_vehicle_uuid,
          },
        })
        .then((res) => {
          commit("SET_DASHBOARD_EVENTS", res.data);
        })
        .catch((err) => {
          dispatch(
            "notifications/addNotification",
            {
              message: err.response.data.error,
              type: "error",
            },
            { root: true }
          );
          console.log(err);
        });
    },
    deleteEvent({ dispatch, rootGetters }, eventId) {
      const user = rootGetters["user/getUser"];
      axios
        .delete(process.env.VUE_APP_SERVER_URL + `/vehicle/event/${eventId}`, {
          params: {
            vehicle_id: user.active_vehicle_uuid,
          },
        })
        .then(() => {
          dispatch("loadDashboardEvents");
          dispatch("loadEvents");
        })
        .catch((err) => {
          dispatch(
            "notifications/addNotification",
            {
              message: err.response.data.error,
              type: "error",
            },
            { root: true }
          );
          console.log(err);
        });
    },
  },
};
