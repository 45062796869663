<template>
  <div class="navigation-bar" :class="{ 'navigation-bar--ios': $util.isIOS() }">
    <router-link
      :to="link.link"
      class="navigation-bar__link"
      v-for="(link, index) in links"
      :key="index"
      :class="{
        'navigation-bar__link--active': link.link === $route.path,
        'messages-unread': link.label === 'messages' && hasUnreadMessages,
      }"
    >
      <inline-svg
        class="navigation-bar__icon"
        :src="require(`@/assets/icons/${link.icon}.svg`)"
        aria-label="type"
        v-if="link.icon != ''"
      ></inline-svg>
      <div class="bubble-btn" v-else>
        <inline-svg
          class="navigation-bar__icon scan"
          :src="require(`@/assets/icons/scan_text.svg`)"
          aria-label="type"
        ></inline-svg>
      </div>
      {{ $t(`components.navigationBar.link.${link.label}`) }}
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NavigationBar",
  data: () => ({
    links: [
      {
        icon: "gas-station-fuel",
        link: "/",
        label: "refuel",
      },
      {
        icon: "events",
        link: "/activities",
        label: "activity",
      },
      {
        icon: "",
        link: "/activity/create",
        label: "empty",
      },
      {
        icon: "bar-chart-2",
        link: "/statistic",
        label: "statistic",
      },
      {
        icon: "message",
        link: "/messages",
        label: "messages",
      },
      /*{
        icon: "dot-menu",
        link: "/settings",
        label: "settings",
      },*/
    ],
    hasUnreadMessages: false,
  }),
  computed: {
    ...mapGetters("message", ["getMessages", "isLoaded"]),
    ...mapGetters("user", ["isLoggedIn"]),
  },
  mounted() {
    if (this.isLoggedIn) {
      if (!this.isLoaded) {
        this.loadMessages().then(() => {
          this.hasUnreadMessages =
            this.getMessages.filter((m) => m.read_at === null).length > 0;
        });
      } else {
        this.hasUnreadMessages =
          this.getMessages.filter((m) => m.read_at === null).length > 0;
      }
    }
  },
  methods: {
    ...mapActions("message", ["loadMessages"]),
  },
};
</script>

<style lang="scss" scoped>
.navigation-bar {
  @apply py-2 px-2 grid grid-cols-5 bg-white fixed bottom-0 left-0 w-full;
  z-index: 2000;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.06);

  &--ios {
    @apply pb-6;
  }

  &__link {
    @apply flex flex-col justify-center items-center text-xs text-gray-400 font-medium relative;
    transition: all 0.3s ease-in-out;

    &--active {
      @apply text-vi-primary;
    }
  }

  &__icon {
    @apply mb-1 stroke-current w-6;
    &.scan {
      margin-top: 3px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .bubble-btn {
    @apply rounded-full bg-vi-primary text-white p-3 absolute shadow-lg;
    width: 55px;
    height: 55px;
    top: -25px;
  }

  .messages-unread {
    &:before {
      @apply absolute w-2 h-2 bg-green-500 rounded-xl;
      content: "";
      top: -1px;
      right: 20px;
    }
  }
}
</style>
