export const productRoutes = [
  {
    path: "/product-overview/:type/:id",
    name: "ProductOverview",
    component: () => import("./views/ProductOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/product-detail/:id",
    name: "ProductDetails",
    component: () => import("./views/ProductDetail.vue"),
    meta: { requiresAuth: true },
  },
];
