export const voucherRoutes = [
  {
    path: "/voucher-overview",
    name: "VoucherOverview",
    component: () => import("./views/VoucherOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/voucher-detail/:id",
    name: "VoucherDetail",
    component: () => import("./views/VoucherDetail.vue"),
    meta: { requiresAuth: true },
  },
];
