import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
import { coreRoutes } from "@/modules/core/router";
import { voucherRoutes } from "@/modules/voucher/router";
import { activityRoutes } from "@/modules/activity/router";
import { cmsRoutes } from "@/modules/cms/router";
import { eventRoutes } from "@/modules/event/router";
import { packageRoutes } from "@/modules/package/router";
import { productRoutes } from "@/modules/product/router";
import { userRoutes } from "@/modules/user/router";
import { oilRoutes } from "@/modules/oil/router";
import { messageRoutes } from "@/modules/message/router";
import { carRoutes } from "@/modules/car/router";
import { vinRoutes } from "@/modules/vin/router";
import { fluidRoutes } from "@/modules/fluid/router";
import { gasStationRoutes } from "@/modules/gas/router";
import { statisticRoutes } from "@/modules/statistic/router";

const routes = [
  ...coreRoutes,
  ...activityRoutes,
  ...carRoutes,
  ...cmsRoutes,
  ...eventRoutes,
  ...fluidRoutes,
  ...gasStationRoutes,
  ...messageRoutes,
  ...oilRoutes,
  ...packageRoutes,
  ...productRoutes,
  ...userRoutes,
  ...vinRoutes,
  ...voucherRoutes,
  ...statisticRoutes,
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  navigator.serviceWorker
    .register(`${process.env.BASE_URL}service-worker.js`)
    .then((reg) => {
      reg.update();
    });

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Cookies.get("accessToken")) {
      if (!Cookies.get("hasSeenOnboarding")) {
        if (Cookies.get("referral")) {
          next({
            name: "OnboardingTyped",
            params: {
              type: "voucher",
            },
          });
        } else {
          next({ name: "Onboarding" });
        }
      } else {
        next({ name: "LoginChoice" });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
