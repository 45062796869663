<template>
  <div :class="classes">
    <div class="notification__message">
      {{ $t(`${notification.message}`) }}
    </div>
    <inline-svg
      class="notification__icon"
      :src="require('@/assets/icons/x.svg')"
      aria-label="type"
      @click="closeNotification"
    ></inline-svg>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { gsap } from "gsap";

export default {
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
    noteIndex: {
      type: null,
      default: "",
    },
  },
  mounted() {
    if (!this.notification.permanent) {
      setTimeout(() => {
        this.closeNotification();
      }, 2000);
    }
  },
  computed: {
    classes() {
      return [
        "notification",
        `notification--${this.notificationType}`,
        `notification--${this.noteIndex}`,
      ];
    },
    notificationType() {
      return this.notification.type ? this.notification.type : "info";
    },
  },
  methods: {
    ...mapActions("notifications", ["deleteNotification"]),
    closeNotification() {
      var tl = gsap.timeline();

      tl.to(`.notification--${this.noteIndex}`, {
        y: 16,
        opacity: 0,
        duration: 0.5,
        onComplete: this.delete,
      });
    },
    delete() {
      this.deleteNotification(this.notification);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  @apply p-4 rounded-xl shadow-lg font-medium w-full relative;
  z-index: 100001;

  &__message {
    width: calc(100% - 24px);
  }

  &__icon {
    @apply absolute top-4 right-4;
  }

  &--info {
    @apply bg-white text-vi-dark-blue;
  }

  &--error {
    @apply bg-red-100 text-red-900;
  }

  &--warning {
    @apply bg-orange-100 text-orange-900;
  }

  &--offline {
    @apply bg-gray-700 text-white;
  }

  &--success {
    @apply bg-green-100 text-green-900;
  }
}
</style>
