export const cmsRoutes = [
  {
    path: "/page/export",
    name: "Export",
    component: () => import("./views/CmsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/page/contact",
    name: "Contact",
    component: () => import("./views/CmsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/page/imprint",
    name: "Imprint",
    component: () => import("./views/CmsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/page/privacy",
    name: "Privacy",
    component: () => import("./views/CmsPage.vue"),
  },
  {
    path: "/page/terms",
    name: "Terms",
    component: () => import("./views/CmsPage.vue"),
  },
];
