import axios from "axios";

export default {
  namespaced: true,
  state: {
    vin: null,
    results: {},
  },
  getters: {
    getVin: (state) => state.vin,
    getResults: (state) => state.results,
  },
  mutations: {
    SET_VIN(state, vin) {
      state.vin = vin;
    },
    SET_RESULTS(state, results) {
      state.results = results;
    },
  },
  actions: {
    identifyVin({ dispatch }, image) {
      axios
        .post(process.env.VUE_APP_SERVER_URL + "/ocr/vin", {
          image: image.replace("data:image/png;base64,", ""),
        })
        .then((res) => {
          const data = res.data;

          if (data.vin.length === 17) {
            dispatch("checkStop", data.vin);
          } else {
            dispatch("checkStop", "empty");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkStop({ commit, state }, vin) {
      let results = state.results;
      if (!results[vin]) {
        results[vin] = 1;
        commit("SET_RESULTS", results);
      } else {
        results[vin] += 1;
        commit("SET_RESULTS", results);
      }

      if (vin != "empty" && results[vin] > 2) {
        commit("SET_VIN", vin);
      } else if (vin == "empty" && results[vin] > 9) {
        commit("SET_VIN", vin);
      }
    },
    setVin({ commit }, vin) {
      commit("SET_VIN", vin);
    },
    resetVin({ commit }) {
      commit("SET_VIN", null);
      commit("SET_RESULTS", {});
    },
  },
};
