export const messageRoutes = [
  {
    path: "/messages",
    name: "Messages",
    component: () => import("./views/MessageOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/message-detail/:id",
    name: "MessageDetail",
    component: () => import("./views/MessageDetail.vue"),
    meta: { requiresAuth: true },
  },
];
