import axios from "axios";
import { showErrorNotification } from "@/utils/errors";

export default {
  namespaced: true,
  state: {
    item: null,
    list: null,
    dashboardList: null,
    searchList: null,
    subList: null,
    subDetails: null,
    details: null,
    genericList: null,
  },
  getters: {
    getWearpart: (state) => state.item,
    getList: (state) => state.list,
    getSubList: (state) => state.subList,
    getSubDetails: (state) => state.subDetails,
    getDashboardList: (state) => state.dashboardList,
    getSearchList: (state) => state.searchList,
    getDetails: (state) => state.details,
  },
  mutations: {
    SET_WEARPART(state, item) {
      state.item = item;
    },
    SET_LIST(state, list) {
      state.list = list;
    },
    SET_DASHBOARD_LIST(state, list) {
      state.dashboardList = list;
    },
  },
  actions: {
    loadWearparts({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle/wearpart", {
            params: {
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_LIST", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadDashboardWearparts({ commit, dispatch }, carId) {
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/vehicle/wearpart/dashboard", {
          params: {
            vehicle_id: carId,
          },
        })
        .then((res) => {
          commit("SET_DASHBOARD_LIST", res.data);
        })
        .catch((err) => {
          showErrorNotification(dispatch, err);
        });
    },
    setWearpart({ commit }, item) {
      commit("SET_WEARPART", item);
    },
    loadSubDetails({ commit, rootGetters, dispatch }, identifier) {
      return new Promise((resolve, reject) => {
        const user = rootGetters["user/getUser"];
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/vehicle/part/`, {
            params: {
              submodule_id: identifier,
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_SUB_DETAILS", res.data);
            resolve();
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject(err);
          });
      });
    },
    loadProducts({ commit, rootGetters, dispatch }, identifier) {
      const user = rootGetters["user/getUser"];
      axios
        .get(process.env.VUE_APP_SERVER_URL + `/vehicle/subwearpart`, {
          params: {
            submodule_id: identifier,
            vehicle_id: user.active_vehicle_uuid,
          },
        })
        .then((res) => {
          commit("SET_SUB_DETAILS", res.data);
        })
        .catch((err) => {
          showErrorNotification(dispatch, err);
        });
    },
  },
};
