import axios from "axios";
import { showErrorNotification } from "@/utils/errors";

export default {
  namespaced: true,
  state: {
    fluid: null,
    list: null,
    dashboardList: null,
    details: null,
  },
  getters: {
    getFluid: (state) => state.fluid,
    getList: (state) => state.list,
    getDetails: (state) => state.details,
  },
  mutations: {
    SET_FLUID(state, fluid) {
      state.fluid = fluid;
    },
    SET_LIST(state, list) {
      state.list = list;
    },
    SET_DETAILS(state, payload) {
      state.details = payload;
    },
    CLEAR_STATE(state) {
      state.fluid = null;
      state.list = null;
      state.dashboardList = null;
      state.details = null;
    },
  },
  actions: {
    loadFluids({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle/fluid", {
            params: {
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_LIST", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadDetails({ commit, rootGetters, dispatch }, identifier) {
      return new Promise((resolve, reject) => {
        const user = rootGetters["user/getUser"];
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/vehicle/part/`, {
            params: {
              generic_article_id: identifier,
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_SUB_DETAILS", res.data);
            resolve();
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject(err);
          });
      });
    },
    setFluid({ commit }, fluid) {
      commit("SET_FLUID", fluid);
    },
    setList({ commit }, list) {
      commit("SET_LIST", list);
    },
    clear({ commit }) {
      commit("CLEAR_STATE");
    },
  },
};
