export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {
    getNotifications: (state) => state.notifications,
  },
  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
  },
  actions: {
    addNotification({ commit, state }, message) {
      let notifications = state.notifications;

      notifications.push(message);
      commit("SET_NOTIFICATIONS", notifications);
    },
    deleteNotification({ commit, state }, message) {
      let notifications = state.notifications.filter((note) => {
        return note.message !== message.message && note.type !== message.type;
      });
      commit("SET_NOTIFICATIONS", notifications);
    },
  },
};
