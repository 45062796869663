export const workshopRoutes = [
  {
    path: "/workshop",
    name: "WorkshopOverview",
    component: () => import("./views/WorkshopOverview.vue"),
    meta: { requiresAuth: true, disablePull: true },
  },
  {
    path: "/workshop-detail/:id",
    name: "WorkshopDetail",
    component: () => import("./views/WorkshopDetail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/workshop-booking",
    name: "WorkshopBookingOverview",
    component: () => import("./views/WorkshopBookingOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/workshop-booking/:id",
    name: "WorkshopBookingDetail",
    component: () => import("./views/WorkshopBookingDetail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/workshop/booking/service",
    name: "WorkshopBookingServiceSelect",
    component: () => import("./views/booking/ServiceSelect.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/workshop/booking/car",
    name: "WorkshopBookingCarSelect",
    component: () => import("./views/booking/CarSelect.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/workshop/booking/car-details",
    name: "WorkshopBookingCarDetails",
    component: () => import("./views/booking/CarDetails.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/workshop/booking/location",
    name: "WorkshopBookingLocationSelect",
    component: () => import("./views/booking/LocationSelect.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/workshop/booking/workshop",
    name: "WorkshopBookingWorkshopSelect",
    component: () => import("./views/booking/WorkshopSelect.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/workshop/booking/customer",
    name: "WorkshopBookingCustomerData",
    component: () => import("./views/booking/CustomerData.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/workshop/booking/date",
    name: "WorkshopBookingDateSelect",
    component: () => import("./views/booking/DateSelect.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/workshop/booking/review",
    name: "WorkshopBookingReview",
    component: () => import("./views/booking/Review.vue"),
    meta: { requiresAuth: true },
  },
];
