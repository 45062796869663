export const packageRoutes = [
  {
    path: "/packages-overview",
    name: "PackagesOverview",
    component: () => import("./views/PackageOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/packages-search",
    name: "PackagesSearch",
    component: () => import("./views/PackageSearch.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/packages-sub/:id",
    name: "PackagesSub",
    component: () => import("./views/PackageSubOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/packages-sub-details/:type/:id",
    name: "PackagesSubDetails",
    component: () => import("./views/PackageSubDetail.vue"),
    meta: { requiresAuth: true },
  },
];
