export const activityRoutes = [
  {
    path: "/activities",
    name: "ActivityOverview",
    component: () => import("./views/ActivityOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/activity/create",
    name: "ActivityCreateOverview",
    component: () => import("./views/ActivityCreateOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/activity/scan",
    name: "ActivityScanOverview",
    component: () => import("./views/ActivityScanOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/activity/manual",
    name: "ActivityManualOverview",
    component: () => import("./views/ActivityManualOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/activity/scan/mileage",
    name: "ActivityScanMileage",
    component: () => import("./views/ActivityScanMileage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/activity/create/:type",
    name: "ActivityCreate",
    component: () => import("./views/ActivityCreate.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/activity/edit/:id",
    name: "ActivityEdit",
    component: () => import("./views/ActivityEdit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/activity/:id",
    name: "ActivityDetail",
    component: () => import("./views/ActivityDetail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/image/edit",
    name: "ImageEditView",
    component: () => import("./views/ImageEdit.vue"),
    meta: { requiresAuth: false },
  },
];
