import axios from "axios";
import {
  showErrorNotification,
  showSuccessNotification,
  startLoading,
  stopLoading,
} from "@/utils/errors";

export default {
  namespaced: true,
  state: {
    voucher: null,
    list: null,
    dashboardList: null,
  },
  getters: {
    getVoucher: (state) => state.voucher,
    getList: (state) => state.list,
    getDetails: (state) => state.details,
  },
  mutations: {
    SET_VOUCHER(state, voucher) {
      state.voucher = voucher;
    },
    SET_LIST(state, list) {
      state.list = list;
    },
    SET_DETAILS(state, payload) {
      state.details = payload;
    },
  },
  actions: {
    loadVoucher({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        startLoading(dispatch);
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/user/voucher")
          .then((res) => {
            commit("SET_LIST", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          })
          .finally(stopLoading(dispatch));
      });
    },
    loadDetails({ commit, dispatch }, voucher) {
      return new Promise((resolve, reject) => {
        startLoading(dispatch);
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/user/voucher/${voucher.uuid}`)
          .then((res) => {
            commit("SET_VOUCHER", res.data);
            resolve(res);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject(err);
          })
          .finally(stopLoading(dispatch));
      });
    },
    activateVoucher({ commit, dispatch }, voucher) {
      return new Promise((resolve, reject) => {
        startLoading(dispatch);
        axios
          .patch(
            process.env.VUE_APP_SERVER_URL + `/user/voucher/${voucher.uuid}/`,
            {
              active: true,
            }
          )
          .then((res) => {
            commit("SET_VOUCHER", res.data);
            showSuccessNotification(
              dispatch,
              "voucher.detail.successfulActivated"
            );
            resolve();
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject(err);
          })
          .finally(stopLoading(dispatch));
      });
    },
    setVoucher({ commit }, voucher) {
      commit("SET_VOUCHER", voucher);
    },
    setList({ commit }, list) {
      commit("SET_LIST", list);
    },
  },
};
