<template>
  <div class="loading-bar">
    <div class="loading-bar__bar"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@keyframes loader-animation {
  0% {
    left: -100%;
  }

  49% {
    left: 100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

.loading-bar {
  height: 5px;
  width: 100%;
  overflow: hidden;

  &__bar {
    position: relative;
    height: 5px;
    width: 100%;
    background-color: dodgerblue;
    animation-name: loader-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
</style>
