export const coreRoutes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("./views/CoreDashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("./views/Test.vue"),
  },

  {
    path: "/generic-settings",
    name: "GenericSettings",
    component: () => import("./views/GenericSettings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/profile-settings",
    name: "ProfileSettings",
    component: () => import("./views/ProfileSettings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("./views/Settings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: () => import("./views/Onboarding.vue"),
    meta: { navbar: false },
  },
  {
    path: "/onboarding/:type",
    name: "OnboardingTyped",
    component: () => import("./views/Onboarding.vue"),
    meta: { navbar: false },
  },
  {
    path: "/wearparts-overview/",
    name: "WearpartsOverview",
    component: () => import("./views/WearpartsOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/access",
    name: "Access",
    component: () => import("./views/AccessCode.vue"),
  },
];
