import moment from "moment";

export default {
  install: (app) => {
    var formatter = {
      number(num, local) {
        const options = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };

        if (num === null) {
          return "";
        }

        if (local) {
          return Number(num).toLocaleString(local, options);
        }

        return Number(num).toLocaleString("de", options);
      },

      formatPrice(price, local) {
        const options = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };

        if (price === null) {
          return "";
        }

        if (local) {
          return Number(price).toLocaleString(local, options);
        }

        return Number(price).toLocaleString("de", options);
      },

      formatFuelPrice(price, local) {
        const options = {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        };

        if (price === null) {
          return "";
        }

        if (local) {
          return Number(price).toLocaleString(local, options);
        }

        return Number(price).toLocaleString("de", options);
      },

      formatFuelPriceHtml(price, local) {
        let fPrice = this.formatFuelPrice(price, local);
        return `${fPrice.substring(
          0,
          fPrice.length - 1
        )}<sup>${fPrice.substring(fPrice.length - 1)}</sup>`;
      },

      formatMileage(mileage) {
        mileage = mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return `${mileage} km`;
      },

      formatDate(dateString) {
        return moment(dateString).format("DD.MM.yyyy");
      },

      formatDateTime(dateString) {
        return moment(dateString).format("DD.MM.yyyy HH:mm");
      },
    };

    app.config.globalProperties.$format = formatter;
  },
};
