import axios from "axios";

export default {
  namespaced: true,
  state: {
    vehicles: JSON.parse(localStorage.getItem("car")) || [],
    mainCar: JSON.parse(localStorage.getItem("mainCar")) || null,
    car: null,
    carPictures: null,
  },
  getters: {
    getVehicles: (state) => state.vehicles,
    getCar: (state) => state.car,
    getMainCar: (state) => state.mainCar,
  },
  mutations: {
    SET_VEHICLES(state, vehicles) {
      state.vehicles = vehicles;
    },
    SET_MAIN_CAR(state, car) {
      state.mainCar = car;
    },
    SET_CAR(state, car) {
      state.car = car;
    },
    SET_CAR_PICTURES(state, pictures) {
      state.carPictures = pictures;
    },
    CLEAR_STATE(state) {
      state.vehicles = [];
      state.mainCar = null;
      state.car = null;
      state.carPictures = null;
    },
  },
  actions: {
    loadVehicles({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle")
          .then((res) => {
            commit("SET_VEHICLES", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            console.log(err);
            reject();
          });
      });
    },
    setMainCar({ commit, dispatch }, car) {
      commit("SET_MAIN_CAR", car);
      dispatch("fluids/clear", null, { root: true });
    },
    loadMainCar({ dispatch }, carId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/vehicle/${carId}`)
          .then((res) => {
            dispatch("setMainCar", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: `car.notification.error.${err.response.data.error}`,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          });
      });
    },
    loadCar({ commit, dispatch }, carId) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/vehicle/${carId}`)
          .then((res) => {
            commit("SET_CAR", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: `car.notification.error.${err.response.data.error}`,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          });
      });
    },
    loadCarPictures({ commit, dispatch }, carId) {
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/vehicle/media", {
          params: { vehicle_id: carId },
        })
        .then((res) => {
          commit("SET_CAR_PICTURES", res.data);
        })
        .catch((err) => {
          dispatch(
            "notifications/addNotification",
            {
              message: `car.notification.error.${err.response.data.error}`,
              type: "error",
            },
            { root: true }
          );
          console.log(err);
        });
    },
    registerCar({ commit, rootGetters, dispatch }) {
      const vin = rootGetters["vin/getVin"];
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SERVER_URL + "/vehicle", {
            vin: vin,
          })
          .then((res) => {
            commit("SET_MAIN_CAR", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            switch (err.response.status) {
              case 422:
                reject("notifications.error.carAlreadyRegistered");
                dispatch(
                  "notifications/addNotification",
                  {
                    message: `car.notification.error.${err.response.data.error}`,
                    type: "error",
                  },
                  { root: true }
                );
                break;
              case 403:
                reject("notifications.error.noVinLeft");
                dispatch(
                  "notifications/addNotification",
                  {
                    message: err.response.data.error,
                    type: "error",
                  },
                  { root: true }
                );
                break;
              case 404:
                reject("notifications.error.default");
                dispatch(
                  "notifications/addNotification",
                  {
                    message: `car.notification.error.${err.response.data.error}`,
                    type: "error",
                  },
                  { root: true }
                );
                break;
              case 500:
                reject("notifications.error.noCarFound");
                dispatch(
                  "notifications/addNotification",
                  {
                    message: `car.notification.error.${err.response.data.error}`,
                    type: "error",
                  },
                  { root: true }
                );
                break;
              default:
            }
          });
      });
    },
    updateVehicle({ dispatch }, car) {
      return new Promise((resolve, reject) => {
        axios
          .patch(process.env.VUE_APP_SERVER_URL + "/vehicle", {
            custom_fields: car.custom_fields,
            vehicle_id: car.uuid,
          })
          .then(() => {
            dispatch("loadCar", car.uuid);
            resolve();
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: `car.notification.error.${err.response.data.error}`,
                type: "error",
              },
              { root: true }
            );
            console.log(err);
            reject();
          });
      });
    },
    deleteVehicle({ commit, dispatch }, car) {
      return new Promise((resolve, reject) => {
        axios
          .delete(process.env.VUE_APP_SERVER_URL + `/vehicle/${car.uuid}`)
          .then(() => {
            if (car == this.state.mainCar) {
              commit("SET_MAIN_CAR", null);
            }

            commit("SET_CAR", null);
            resolve();
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: `car.notification.error.${err.response.data.error}`,
                type: "error",
              },
              { root: true }
            );
            console.log(err);
            reject();
          });
      });
    },
    checkVehicleStatus({ dispatch }, vehicle_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_SERVER_URL +
              `/vehicle/create_status/${vehicle_id}`
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: `car.notification.error.${err.response.data.error}`,
                type: "error",
              },
              { root: true }
            );
            console.log(err);
            reject(err);
          });
      });
    },
    clear({ commit }) {
      commit("CLEAR_STATE");
    },
  },
};
