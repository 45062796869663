import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
import { coreRoutes } from "@/modules/core/routermvp";
import { voucherRoutes } from "@/modules/voucher/router";
import { activityRoutes } from "@/modules/activity/router";
import { cmsRoutes } from "@/modules/cms/router";
import { userRoutes } from "@/modules/user/routermvp";
import { messageRoutes } from "@/modules/message/router";
import { gasStationRoutes } from "@/modules/gas/router";
import { statisticRoutes } from "@/modules/statistic/router";
import { workshopRoutes } from "@/modules/workshop/router";

const routes = [
  ...coreRoutes,
  ...activityRoutes,
  ...cmsRoutes,
  ...gasStationRoutes,
  ...messageRoutes,
  ...userRoutes,
  ...voucherRoutes,
  ...statisticRoutes,
  ...workshopRoutes,
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  navigator.serviceWorker
    ?.register(`${process.env.BASE_URL}service-worker.js`)
    .then((reg) => {
      reg.update();
    });

  if (!Cookies.get("termsAccepted") && to.name !== "FirstStart") {
    next({
      name: "FirstStart",
    });
  } else if (!Cookies.get("termsAccepted") && to.name == "FirstStart") {
    next();
  }

  if (!Cookies.get("hasSeenOnboarding") && to.name !== "Onboarding") {
    if (Cookies.get("referral")) {
      next({
        name: "OnboardingTyped",
        params: {
          type: "voucher",
        },
      });
    } else {
      next({ name: "Onboarding" });
    }
  }

  /*if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Cookies.get("accessToken")) {
      next({
        name: "LoginChoice",
        params: {
          showHint: true,
        },
      });
    }
  }*/

  next();
});

export default router;
