import axios from "axios";
import { showErrorNotification } from "@/utils/errors";

export default {
  namespaced: true,
  state: {
    oil: null,
    list: null,
    dashboardList: null,
    searchList: null,
    subList: null,
    subDetails: null,
    details: null,
    genericList: null,
  },
  getters: {
    getOil: (state) => state.oil,
    getList: (state) => state.list,
    getDetails: (state) => state.details,
  },
  mutations: {
    SET_OIL(state, oil) {
      state.oil = oil;
    },
    SET_LIST(state, list) {
      state.list = list;
    },
    SET_DETAILS(state, payload) {
      state.details = payload;
    },
  },
  actions: {
    loadOils({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle/oil", {
            params: {
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_LIST", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadDetails({ commit, rootGetters, dispatch }, identifier) {
      return new Promise((resolve, reject) => {
        const user = rootGetters["user/getUser"];
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/vehicle/part/`, {
            params: {
              generic_article_id: identifier,
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_SUB_DETAILS", res.data);
            resolve();
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject(err);
          });
      });
    },
    loadProducts({ commit, rootGetters, dispatch }, identifier) {
      const user = rootGetters["user/getUser"];
      axios
        .get(process.env.VUE_APP_SERVER_URL + `/vehicle/subpackage`, {
          params: {
            submodule_id: identifier,
            vehicle_id: user.active_vehicle_uuid,
          },
        })
        .then((res) => {
          commit("SET_SUB_DETAILS", res.data);
        })
        .catch((err) => {
          showErrorNotification(dispatch, err);
        });
    },
    setOil({ commit }, oil) {
      commit("SET_OIL", oil);
    },
    setList({ commit }, list) {
      commit("SET_LIST", list);
    },
  },
};
