import router from "@/router";

export default {
  namespaced: true,
  state: {
    history: null,
  },
  getters: {
    getHistory: (state) => state.history,
    hasHistory: () => window.history.length > 2,
  },
  mutations: {
    SET_HISTORY(state, history) {
      state.history = history;
    },
  },
  actions: {
    goBack({ getters }) {
      getters.hasHistory ? router.go(-1) : router.push("/");
    },
  },
};
