import Cookies from "js-cookie";

export default {
  install: (app) => {
    var utility = {
      isIOS() {
        return (
          !!navigator.platform &&
          /iPad|iPhone|iPod|Mac/.test(navigator.platform)
        );
      },

      isIOSApp() {
        return this.isIOS() && this.isApp();
      },

      isAndroid() {
        return !!navigator.platform && /Android/.test(navigator.platform);
      },

      isAndroidApp() {
        return this.isAndroid() && this.isApp();
      },

      isApp() {
        return Cookies.get("app-platform");
      },
    };

    app.config.globalProperties.$util = utility;
  },
};
