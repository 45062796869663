import axios from "axios";
import { Geolocation } from "@capacitor/geolocation";
import { latLng } from "leaflet";

import {
  showErrorNotification,
  startLoading,
  stopLoading,
} from "@/utils/errors";

export default {
  namespaced: true,
  state: {
    workshop: null,
    workshop_list: null,
    workshop_booking: null,
    workshop_booking_list: null,
    dashboardList: null,
    settings: JSON.parse(localStorage.getItem("workshop.settings")) || {
      sortBy: "distance",
      radius: 10,
      fav: false,
      brands: [],
    },
    position: null,
  },
  getters: {
    getWorkshop: (state) => state.workshop,
    getWorkshops: (state) => state.workshop_list,
    getWorkshopBooking: (state) => state.workshop_booking,
    getWorkshopBookings: (state) => state.workshop_booking_list,
    getList: (state) => state.workshop_list,
    getSettings: (state) => state.settings,
    getSetting: (state) => (key) => {
      return state.settings[key] ? state.settings[key] : null;
    },
  },
  mutations: {
    SET_WORKSHOP(state, workshop) {
      state.workshop = workshop;
    },
    SET_WORKSHOP_LIST(state, list) {
      state.workshop_list = list;
    },
    SET_BOOKING(state, booking) {
      state.workshop_booking = booking;
    },
    SET_BOOKING_LIST(state, list) {
      state.workshop_booking_list = list;
    },
    SET_LOCATION_PERMISSION(state, permission) {
      state.locationPermission = permission;
      localStorage.setItem(
        "gas_station.locationPermission",
        JSON.stringify(permission)
      );
    },
    SET_POSITION(state, position) {
      state.position = position;
    },
    SET_BOOKING_DATA(state, data) {
      state.booking_data = data;
    },
  },
  actions: {
    loadWorkshopBookings({ commit, dispatch, rootGetters }) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        startLoading(dispatch);
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/workshop/booking", {
            params: {
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_BOOKING_LIST", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          })
          .finally(stopLoading(dispatch));
      });
    },
    loadWorkshopBooking({ commit, dispatch, rootGetters }, id) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        startLoading(dispatch);
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/workshop/booking/${id}`, {
            params: {
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_BOOKING", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          })
          .finally(stopLoading(dispatch));
      });
    },
    cancelWorkshopBooking({ commit, dispatch, rootGetters }, id) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        startLoading(dispatch);
        axios
          .put(process.env.VUE_APP_SERVER_URL + `/workshop/booking/${id}`, {
            vehicle_id: user.active_vehicle_uuid,
            canceled: true,
          })
          .then((res) => {
            commit("SET_BOOKING", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          })
          .finally(stopLoading(dispatch));
      });
    },
    loadWorkshops({ commit, dispatch }, parameter) {
      return new Promise((resolve, reject) => {
        startLoading(dispatch);
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/workshop", {
            params: {
              lat: parameter?.location?.lat,
              lng: parameter?.location?.lng,
            },
          })
          .then((res) => {
            commit("SET_WORKSHOP_LIST", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          })
          .finally(stopLoading(dispatch));
      });
    },
    loadWorkshop({ commit, dispatch }, workshop_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/workshop/${workshop_id}`)
          .then((res) => {
            commit("SET_WORKSHOP", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          });
      });
    },
    checkPermissions() {},
    hasPositionPermission({ state, commit }) {
      console.log("CHECK POS PERMISSION");
      return new Promise((resolve, reject) => {
        if (state.locationPermission) {
          resolve(state.locationPermission == "granted");
        }

        Geolocation.checkPermissions()
          .then((res) => {
            console.log("CHECKED PERMISSION", res);
            if (res.location === "denied") {
              commit("SET_LOCATION_PERMISSION", "denied");
              resolve(false);
            } else if (res.location === "granted") {
              commit("SET_LOCATION_PERMISSION", "granted");
              resolve(true);
            } else if (res.location === "prompt") {
              Geolocation.requestPermissions()
                .then((res) => {
                  console.log("REQUESTED PERMISSION", res);
                })
                .catch(() => {
                  Geolocation.getCurrentPosition()
                    .then((location) => {
                      console.log("CURRENT POSITION", location);
                      commit(
                        "SET_POSITION",
                        latLng(
                          location.coords.latitude,
                          location.coords.longitude
                        )
                      );
                    })
                    .catch((err) => {
                      if (err.code === 1) {
                        commit("SET_LOCATION_PERMISSION", "denied");
                        resolve(false);
                      }

                      resolve(false);
                    });
                });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updatePosition({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        console.log("UPDATE POSITION", state.locationPermission);

        if (state.locationPermission !== "granted") {
          if (state.locationPermission === "denied") {
            dispatch(
              "notifications/addNotification",
              {
                message: "gasStation.notifications.position_denied",
                type: "error",
              },
              { root: true }
            );
          }
          return reject(false);
        }

        try {
          Geolocation.getCurrentPosition()
            .then((location) => {
              commit(
                "SET_POSITION",
                latLng(location.coords.latitude, location.coords.longitude)
              );
              resolve(location);
            })
            .catch(() => {
              reject(false);
            });
        } catch (err) {
          reject(false);
        }
      });
    },
  },
};
